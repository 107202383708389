<template>
  <div>
    <page-title :heading=heading :subheading=subheading :icon=icon></page-title>
    <div class="content">
      <div class="row d-flex justify-content-center">
        <div class="col-md-12 col-lg-6 col-xl-4">
          <div class="card-shadow-primary card-border mb-3 card">
            <VueElementLoading :active="showLoading" spinner="ring" color="var(--primary)" />
            <div class="dropdown-menu-header">
              <div class="dropdown-menu-header-inner card bg-primary">
                <div class="menu-header-content">
                  <v-avatar size="64" color="var(--info)">
                    <img width="64" :src="imageHostname+'/storage/images/avatars/user/'+currentUser.avatar" :alt="currentUser.name" :hidden="!hasAvatar(currentUser.avatar)" />
                    <span class="white--text headline" :hidden="hasAvatar(currentUser.avatar)" >{{currentUser.name.substring(0,1).toUpperCase()}}</span>
                  </v-avatar>
                  <br>
                  <br>
                  <div>
                    <h5 class="menu-header-title">{{currentUser.name}}</h5>
                    <h6 class="menu-header-subtitle">{{currentUser.job_position}}</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-3">
              <ul class="rm-list-borders list-group list-group-flush">
                <li class="list-group-item">
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left">
                        <div class="widget-heading">Фирма:</div>
                        <div class="widget-subheading">{{currentUser.company}}</div>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left">
                        <div class="widget-heading">E-mail:</div>
                        <div class="widget-subheading">{{currentUser.email}}</div>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left">
                        <div class="widget-heading">Телефон:</div>
                        <div class="widget-subheading">{{currentUser.phone}}</div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <h6 class="text-muted text-center font-size-md opacity-4 font-weight-normal">Последна редакция: {{formatDate(currentUser.updated)}} от {{currentUser.last_edited_by_name}}</h6>
            <div class="text-center d-block card-footer">
              <button class="btn btn-info" v-b-modal.modal-edit >Редакция</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-edit" title="Редакция на профил"  no-body hide-footer>
      <VueElementLoading :active="showLoadingModalEdit" spinner="ring" color="var(--primary)" />
      <b-tabs pills card>
        <v-form ref="form" v-model="valid" lazy-validation>
        <b-tab title="Профил" active>

            <v-text-field v-model="user.name" :rules="nameRules" label="Име" required></v-text-field>
            <v-text-field v-model="user.email" type="email" :rules="emailRules" label="E-mail" required></v-text-field>
            <v-text-field v-model="user.phone" type="phone" label="Телефон"></v-text-field>
            <v-text-field v-model="user.company" label="Фирма"></v-text-field>
            <v-text-field v-model="user.job_position" label="Длъжност"></v-text-field>
            <h6 class="text-muted text-center font-size-md opacity-4 font-weight-normal">Последна редакция: {{formatDate(user.updated)}}</h6>
          <div class="divider"></div>
          <div class="text-center">
            <b-button :disabled="!valid" variant="success" @click="updateProfile()">
              Запази промените
            </b-button>
          </div>
        </b-tab>
          <b-tab title="Парола">
            <v-form ref="passForm" v-model="passValid" lazy-validation>
              <v-text-field type="password" :rules="passRules" v-model="p.current" label="Текуща парола" placeholder="Текущата ви парола..." required></v-text-field>
              <v-text-field type="password" :rules="newPassRules" v-model="p.new" label="Нова парола" placeholder=" Новата ви парола..." required></v-text-field>
              <v-text-field type="password" :rules="confirmPasswordRules" v-model="p.confirm" label="Потвърждение на нова парола" placeholder="Повторете своята нова парола..." required></v-text-field>
              <div class="text-center">
                <b-button :disabled="!passValid" variant="success" @click="updatePassword()">
                  Промени паролата
                </b-button>
              </div>
            </v-form>
          </b-tab>
        <b-tab title="Аватар">
          <div class="text-center">
              <v-avatar size="64" color="var(--info)">
                <img width="64" name="avatarEdit" :src="imageHostname+'/storage/images/avatars/user/'+currentUser.avatar" :alt="currentUser.name" :hidden="!hasAvatar(currentUser.avatar)" />
                <span class="white--text headline" :hidden="hasAvatar(currentUser.avatar)" >{{currentUser.name.substring(0,1).toUpperCase()}}</span>
              </v-avatar>
          </div>
          <div class="divider"></div>
          <div class="text-center">
            <input type="file" name="image" id="image" accept="image/*" />
            <br>
            <br>
            <b-button variant="success" @click="uploadAvatar()">
              Качи избрания файл
            </b-button>
          </div>
        </b-tab>
      </v-form>
      </b-tabs>
      <template #modal-footer="{}">


      </template>
    </b-modal>
  </div>

</template>

<script>
import moment from 'moment'
import ProfileService from "../services/profile.service"
import PageTitle from "../Layout/Components/PageTitle.vue";
import {library} from '@fortawesome/fontawesome-svg-core'
import { ToggleButton } from "vue-js-toggle-button";
import {
  faEdit,
  faCamera
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import VueElementLoading from "vue-element-loading";
import swal from "sweetalert";
import emitter from "../common/emitter";

library.add(
    faEdit,
    faCamera
);


export default {
  components: {
    PageTitle,
    ToggleButton,
    VueElementLoading,
    'font-awesome-icon': FontAwesomeIcon,
  },
  computed: {
    confirmPasswordRules() {
      const rules = [(this.p.new === this.p.confirm) || "Паролите трябва да съвпадат."];
      return rules;
    },
  },
  data: () => ({
    imageHostname: Vue.prototype.$hostnameImages,
    heading: 'Профил',
    subheading: 'От тук можете да променяте данните във вашия профил в системата',
    icon: 'pe-7s-id icon-gradient bg-happy-itmeo',
    currentUser:{
      name: "",
      email: ""
    },
    valid: true,
    passValid:false,
    user:
        {
          name: "",
          email: "",
          phone: "",
          company: "",
          job_position: ""
        },
    nameRules: [
      v => !!v || "Полето е задължително",
    ],
    emailRules: [
      v => !!v || "Полето е задължително",
      v => /.+@.+/.test(v) || "Моля въведете валиден e-mail адрес"
    ],
    passRules: [
      v => !!v || "Полето е задължително"
    ],
    newPassRules: [
      v => !!v || "Полето е задължително",
      (v) =>
          (v && v.length >= 8) ||
          "Паролата трябва да е минимум 8 символа",
    ],
    p:
        {
          current:"",
          new:"",
          confirm:""
        },
    showLoading: false,
    showLoadingModalEdit: false,
  }),
  methods:
  {
    getProfile()
    {
      this.showLoading = true
      ProfileService.getRemote().then((response) =>
      { response
        this.currentUser = ProfileService.getLocal();
        this.user = this.currentUser
        this.showLoading = false
      })
    },
    updateProfile()
    {
      if(this.valid && (this.user.email.length>5 && this.user.name.length>3))
      {
        this.showLoadingModalEdit = true;
        ProfileService.update(this.user).then((response) =>
        {
          response

          this.showLoadingModalEdit = false;
          this.$root.$emit('bv::toggle::modal', 'modal-edit', '#btnToggle')
          emitter.emit("updateProfile");
        });
      }
      else
      {
        this.showLoadingModalEdit = false;
        swal("","Моля попълнете всички задължителни полета.","warning");
      }
    },
    updatePassword()
    {
      if(this.passValid && this.p.new.length>7)
      {
        this.showLoadingModalEdit = true;
        ProfileService.changePassword(this.p.current,this.p.new).then((response) =>
        {
          response

          this.showLoadingModalEdit = false;
          this.$root.$emit('bv::toggle::modal', 'modal-edit', '#btnToggle')
          emitter.emit("logout");
        },
        error =>
            {
              this.showLoadingModalEdit = false;
              this.message =
                  (error.response && error.response.data) ||
                  error.message ||
                  error.toString();
              if(this.message.error.status_code==403)
              {
                swal("", "Грешна парола");
              }
              else
              {
                swal("Грешка", this.message.error.message, "error");
              }

            });
      }
    },
    formatDate(date)
    {
      return moment(date).utcOffset('+0000').format('DD.MM.yy HH:mm')
    },
    uploadAvatar()
    {
      var formData = new FormData();
      var imagefile = document.querySelector('#image');
      formData.append("photo", imagefile.files[0]);
      this.showLoadingModalEdit = true;
      ProfileService.updateAvatar(formData).then((response) =>
          {
            response

            this.currentUser.avatar = response.data.response.filename;
            this.showLoadingModalEdit = false;
            emitter.emit("updateProfile");
          },
          error =>
          {
            this.showLoadingModalEdit = false;
            this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
              swal("Грешка", this.message.error.message, "error");
          });
    },
    hasAvatar(avatar)
    {
      if(!avatar)
      {
        return false
      }
      else
      {
        if(avatar.endsWith(".webp"))
        {
          return true
        }
        else
        {
          return false;
        }
      }
    },
  },

  created()
  {
    this.getProfile()
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }

  }
};
</script>
